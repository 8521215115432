<template>
	<div v-on:click="onClick" class="generic-model-view">
		<model-obj ref="model" :backgroundAlpha="0" v-if="isOBJ" :src="object_uri"></model-obj>
		<model-three ref="model" :backgroundAlpha="0" v-if="isThree" :src="object_uri"></model-three>
		<model-stl ref="model" :backgroundAlpha="0" v-if="isSTL" :src="object_uri"></model-stl>
		<model-collada ref="model" :backgroundAlpha="0" v-if="isCollada" :src="object_uri"></model-collada>
		<model-ply ref="model" :backgroundAlpha="0" v-if="isPLY" :src="object_uri"></model-ply>
		<model-fbx ref="model" :backgroundAlpha="0" v-if="isFBX" :src="object_uri"></model-fbx>
		<model-gltf ref="model" :backgroundAlpha="0" v-if="isGLTF" :src="object_uri"></model-gltf>
		<model-viewer ref="model" v-if="isGLB" class="model-view" alt="Item image" ar ar-modes="webxr scene-viewer quick-look" :src="object_uri"></model-viewer>
	</div>
</template>

<script>
	import "@google/model-viewer"
	import {ModelCollada, ModelFbx, ModelGltf, ModelObj, ModelPly, ModelStl, ModelThree} from 'vue-3d-model';
	
	export default {
		name: "GenericModelViewer",
		components: {ModelObj, ModelThree, ModelStl, ModelCollada, ModelPly, ModelFbx, ModelGltf},
		props: {
			object_uri: {
				type: String,
				default: function() {
					return ""
				}
			},
			poster_uri: {
				type: String,
				required: false
			}
		},
		data() {
			return {
				base64: "",
			}
		},
		computed: {
			isOBJ: function () {
				return this.object_uri.endsWith(".obj");
			},
			isThree: function () {
				return this.object_uri.endsWith(".three");
			},
			isSTL: function () {
				return this.object_uri.endsWith(".stl");
			},
			isCollada: function () {
				return this.object_uri.endsWith(".dae");
			},
			isPLY: function () {
				return this.object_uri.endsWith(".ply");
			},
			isFBX: function () {
				return this.object_uri.endsWith(".fbx");
			},
			isGLTF: function () {
				return this.object_uri.endsWith(".gltf");
			},
			isGLB: function () {
				return this.object_uri.endsWith(".glb");
			}
		},
		methods: {
			onClick: function () {
				console.log("Clicked")
				this.snapshot();
				console.log("b64", this.base64)
				this.$emit("click", this.base64);
				this.base64 = "";
			},
			
			snapshot() {
				if (this.isGLB) {
					this.base64 = this.$refs.model.toBlob({mimeType: 'image/png', idealAspect: true});
					return
				}
				
				this.base64 = this.$refs.model.renderer.domElement.toDataURL('image/png', 1);
			}
		}
	}
</script>

<style scoped>
	model-viewer {
		--poster-color: transparent;
		height: 100px;
		width: 100%;
	}
</style>